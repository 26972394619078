import {
  checkoutTypes,
  checkoutPayment,
  seasonInfo,
  paymentTypes
} from '../constants';
import {
  Amount,
  Installment,
  Summary,
  PaymentMethod
} from 'components/Checkout/types';
import { isValidFeature } from './features/';

import Utils from '../global/scripts/utils';
import { addParamToURL } from 'utils/url';
import config from '../../env.config';

const installmentsTypes = {
  DEFAULT: 'label_checkout_installments_other',
  PARCELS: 'label_checkout_installments'
};

export const showCheckoutAtBottom = () => {
  const mobile = 1200;
  if (typeof document !== 'undefined') {
    document.body.classList.remove('modal-opened');
    return document.body.getBoundingClientRect().width < mobile;
  }
};

export const createCheckoutLabel = ({ offer, cta, context }) => {
  const { checkout = {} } = context;
  const { data = {} } = checkout;

  if (data.checkoutClosed) {
    return checkoutTypes.CHECKOUT_CLOSED;
  }

  if (offer) {
    return checkoutTypes.CHECKOUT_CUSTOMIZED;
  }

  if (cta && cta.action === checkoutTypes.CHECKOUT) {
    return checkoutTypes.CHECKOUT_CUSTOMIZED;
  } else {
    return cta?.action;
  }

  return '';
};

export const createCheckoutURL = (
  cta: {
    action: string;
    link: string;
  },
  producerReferenceCode: string,
  off: string,
  src: string | undefined,
  sck: string | undefined,
  coupon: string | undefined,
  hotfeature: string | undefined
) => {
  if (
    cta &&
    cta.action === 'knowMore' &&
    cta.link &&
    `${cta.link || ''}`.trim()
  ) {
    return cta.link;
  }
  const baseURL = `${config.envConfig.CHECKOUT}/${producerReferenceCode}`;
  let finalURL = baseURL;
  let finalFeature = isValidFeature(hotfeature);
  let finalSCK;

  if (sck) {
    finalSCK = sck;
  } else {
    finalSCK = 'HOTMART_PRODUCT_PAGE';
  }

  finalURL = Utils.mountLink(
    finalURL,
    finalSCK,
    src,
    off,
    coupon,
    null,
    null,
    null,
    null,
    finalFeature
  );

  return finalURL;
};

export const isBuyableCheckout = (checkoutLabel) => {
  return checkoutLabel === checkoutTypes.CHECKOUT_CUSTOMIZED;
};

export const mountCheckoutLabelKey = (
  checkoutLabel: string,
  isMobile: boolean
) => {
  let checkoutLabelKey = checkoutLabel;

  if (
    ['checkout', 'customizedCheckout', 'directPayment'].indexOf(
      checkoutLabel
    ) !== -1
  ) {
    checkoutLabelKey = 'buy_cart';
  }
  return checkoutLabelKey;
};

export const mountCheckoutLabel = (
  checkoutLabelKey: string,
  isBuyable: boolean,
  t: (label: string) => void,
  label: string
) => {
  const checkoutButtonLabel = t(
    `salesPage.cta.${
      checkoutLabelKey || (isBuyable ? checkoutLabelKey : label)
    }`
  );

  return checkoutButtonLabel;
};

export const mountAccessCourseLabel = (accessCourse, t) => {
  let accessCourseLabel = t('salesPage.access_product');

  if (accessCourse && accessCourse.bought) {
    if (!accessCourse.club) {
      accessCourseLabel = t('salesPage.link_hub');
    }
  }

  return accessCourseLabel;
};

export const getOfferByType = (offers, type) => {
  return offers?.length ? offers.find((item) => item.type === type) : {};
};

const formatPriceValue = ({
  locale,
  amount
}: {
  locale: string;
  amount: any;
}): string => {
  let currency = amount?.currency;
  if (!locale) {
    locale = navigator.language || checkoutPayment.LOCALE.PT;
  }

  if (currency) {
    return new Intl.NumberFormat(locale.replace('_', '-'), {
      style: 'currency',
      currency: currency
    }).format(amount?.value);
  }

  return null;
};

const isSubscription = (type: string) => {
  return type === checkoutPayment.TYPE.SUBSCRIPTION;
};

const subscriptionRecurrence = (key: string, t: (arg0: string) => string) => {
  const recurrenceKey = {
    label_checkout_weekly: t('checkout.label_checkout_weekly'),
    label_checkout_monthly: t('checkout.label_checkout_monthly'),
    label_checkout_bimonthly: t('checkout.label_checkout_bimonthly'),
    label_checkout_quarterly: t('checkout.label_checkout_quarterly'),
    label_checkout_biannual: t('checkout.label_checkout_biannual'),
    label_checkout_annual: t('checkout.label_checkout_annual'),
    default: ''
  };

  return recurrenceKey[key];
};

const treatLabelInstallments = (label: string) => {
  if (
    label === installmentsTypes.DEFAULT ||
    label === installmentsTypes.PARCELS
  ) {
    return installmentsTypes.DEFAULT;
  }

  return label;
};

const hasInstallments = (amount: Amount, installments: Array<Installment>) => {
  return (
    (amount?.label?.key === installmentsTypes.DEFAULT ||
      amount?.label?.key === installmentsTypes.PARCELS) &&
    installments &&
    installments.length >= 1
  );
};

const getTotalInstallments = (creditOffer: PaymentMethod) => {
  if (creditOffer?.amount?.label?.key === installmentsTypes.DEFAULT) {
    return +creditOffer?.amount?.label?.parameters[0];
  }

  return creditOffer?.installments?.length;
};

const mountIntallments = (
  creditOffer: PaymentMethod,
  locale: string,
  t: any
) => {
  const { amount, installments } = creditOffer;
  const { label } = amount;
  const totalInstallments = getTotalInstallments(creditOffer);

  const parcels = {
    infoPrice: '',
    paymentInInstallments: ''
  };

  if (totalInstallments > 0) {
    installments.map((item) => {
      const { amount, number } = item;
      const treatedLabelOfInstallments = treatLabelInstallments(label?.key);

      if (number === totalInstallments) {
        const installment = formatPriceValue({ locale, amount });

        parcels.infoPrice = t(`checkout.${treatedLabelOfInstallments}`, {
          0: totalInstallments,
          1: installment
        });
        parcels.paymentInInstallments = t(
          `checkout.${treatedLabelOfInstallments}_complementary`,
          { 0: totalInstallments, 1: installment }
        );
      }
    });
  }

  return parcels;
};

const mountPreviousPrice = (summary: Summary, locale: string) => {
  const discounts = {
    previousPrice: '',
    coupon: 0
  };
  let amount = {
    currency: '',
    value: 0
  };

  if (summary?.items?.length > 0) {
    summary?.items?.map((item) => {
      if (item?.type === checkoutPayment.TYPE.FULL_PRICE) {
        amount = {
          currency: item?.currency,
          value: item?.value
        };
      }
      if (item?.type === checkoutPayment.TYPE.COUPON) {
        discounts.coupon = item?.percent;
      }
      if (
        item?.type === checkoutPayment.TYPE.VAT &&
        item?.operation === checkoutPayment.OPERATION.ADD
      ) {
        amount.value += item?.value;
      }
    });
  }

  discounts.previousPrice = formatPriceValue({ locale, amount });

  return discounts;
};

export const mountPriceOffer = (
  offers: PaymentMethod,
  locale: string,
  type: string,
  t: (arg0: string, arg1?: { installment: number }) => string
) => {
  const creditOffer = getOfferByType(offers, paymentTypes.CREDIT_CARD);
  let parcels = { infoPrice: '', paymentInInstallments: '' };
  let price = '';
  let recurrence = '';

  if (creditOffer && Object.keys(creditOffer)?.length > 0) {
    price = formatPriceValue({ locale, amount: creditOffer?.amount });
  }

  if (isSubscription(type)) {
    recurrence =
      subscriptionRecurrence(creditOffer?.amount?.label.key, t) ||
      subscriptionRecurrence('default', t);
    console.log('recurrence:', recurrence);
  } else if (hasInstallments(creditOffer?.amount, creditOffer?.installments)) {
    parcels = mountIntallments(creditOffer, locale, t);
  }

  const { previousPrice, coupon } = mountPreviousPrice(creditOffer?.summary, locale);

  return {
    infoPrice: parcels?.infoPrice,
    paymentInInstallments: parcels?.paymentInInstallments,
    pricePreviousValue: previousPrice,
    priceRecurrence: recurrence,
    priceTitle: price,
    promptPayment: '',
    coupon: coupon,
    vat: ''
  };
};

export const mountPriceTitle = (paymentResponse, chargesCycles, type, t) => {
  let price = {
    infoPrice: '',
    priceTitle: '',
    priceRecurrence: '',
    pricePreviousValue: '',
    promptPayment: '',
    paymentInInstallments: '',
    coupon: 0,
    vat: ''
  };

  if (paymentResponse) {
    const {
      offerPriceTitle,
      offerPriceDescription,
      offerPrice,
      offerFullPrice,
      offerVATValue,
      installmentInterestFee,
      offerPriceWithoutVAT
    } = paymentResponse;

    if (offerPriceTitle && offerPrice) {
      if (offerPriceTitle.labelKey.match('%s')) {
        price = {
          ...price,
          priceTitle: t('checkout.label_checkout_value', {
            ...offerPriceTitle.listParameter
          })
        };

        if (offerPriceDescription) {
          price.infoPrice = t(`checkout.${offerPriceDescription.labelKey}`, {
            ...offerPriceDescription.listParameter
          });

          if (
            offerPriceDescription.labelKey ===
            'label_checkout_installments_other'
          ) {
            price = {
              ...price,
              promptPayment: t('checkout.label_checkout_value', {
                ...offerPriceTitle.listParameter
              }),
              paymentInInstallments: t(
                `checkout.${offerPriceDescription.labelKey}_complementary`,
                { ...offerPriceDescription.listParameter }
              )
            };
          }
        }
      } else {
        if (type !== 'SUBSCRIPTION') {
          price = {
            ...price,
            priceTitle: t(`checkout.label_checkout_value`, {
              0: offerFullPrice.label
            })
          };
          price.infoPrice = t(`checkout.label_checkout_installments_other`, {
            ...offerPriceTitle.listParameter
          });
        } else {
          price = {
            ...price,
            priceTitle: offerPriceTitle.listParameter[0],
            priceRecurrence: t(`checkout.${offerPriceTitle.labelKey}`)
          };
          price.infoPrice =
            chargesCycles > 0
              ? t(`checkout.max_charge_cycles_info`, {
                  maxChargesCycles: chargesCycles
                })
              : '';
        }
      }

      if (offerPrice && offerPrice.label) {
        price.pricePreviousValue = offerPrice.label;
      }

      if (!installmentInterestFee && installmentInterestFee === 0) {
        price.infoPrice = price.infoPrice.replace('*', '');
      }

      if (+offerVATValue?.value) {
        price.priceTitle = t('checkout.label_checkout_value', {
          0: offerPriceWithoutVAT.label
        });
        price.pricePreviousValue = offerPrice.label;
        price.vat = t(`checkout.label_vat_included`, {
          vat: offerVATValue?.label
        });
      }
    }
  }

  return price;
};

export const getAccessLinks = (
  accessCourse: any,
  config: any,
  isBuyable: boolean,
  clubMembershipLink: string
) => {
  let showAccessCourse = false;
  let showCheckout = true;
  let accessCourseLink;

  if (accessCourse && accessCourse.bought) {
    // se o produto foi comprado
    showAccessCourse = true;
    showCheckout = false;
    if (accessCourse.club) {
      accessCourseLink = accessCourse.accessLink;
    } else {
      accessCourseLink = config.envConfig.PRODUCTS
        ? config.envConfig.PRODUCTS
        : null;
    }
  } else if (isBuyable) {
    // se o produto é comprável
    showAccessCourse = false;
    showCheckout = true;
  } else if (clubMembershipLink) {
    // se produto não foi comprado e não é compravel
    showAccessCourse = false;
    showCheckout = true;
  } else {
    showAccessCourse = false;
    showCheckout = true;
  }

  return {
    accessCourseLink,
    showAccessCourse,
    showCheckout
  };
};

export const createCheckoutLink = (
  creditCardPaymentMethodList,
  cta,
  producerReferenceCode,
  off,
  src,
  sck,
  coupon,
  productId,
  xcod
) => {
  if (
    cta &&
    cta.action === 'knowMore' &&
    cta.link &&
    `${cta.link || ''}`.trim()
  ) {
    return cta.link;
  }
  const baseURL = `${config.envConfig.CHECKOUT}/${producerReferenceCode}`;
  let finalURL = baseURL;
  let finalSCK;

  if (sck) {
    finalSCK = sck;
  } else {
    finalSCK = 'HOTMART_PRODUCT_PAGE';
  }

  finalURL = Utils.mountLink(
    finalURL,
    finalSCK,
    src,
    off,
    coupon,
    null,
    null,
    null,
    null
  );

  if (productId && productId === 800719) {
    finalURL = addParamToURL(finalURL, 'split', '12');
  }

  return finalURL;
};

export const getDiscount = (
  context,
  coupon,
  product,
  isBlackNovember,
  newCheckout,
  offer
) => {
  let productCoupon = coupon;
  let discountValue;

  if (
    productCoupon === product?.coupon?.code &&
    isBlackNovember &&
    product?.coupon?.discount &&
    product?.coupon?.couponType === seasonInfo[0].COUPON_TYPE
  ) {
    discountValue = Utils.BlackNovemberDiscount(product.coupon);
    productCoupon = product.coupon.code;
  } else {
    const oldValue = offer?.paymentInfoResponse?.offerPrice?.value || 0;
    const newValue = offer?.paymentInfoResponse?.offerFullPrice?.value || 0;

    const { query: { coupon = null } = {} } = context;

    if (oldValue && newValue && coupon) {
      discountValue = Utils.discountAmount(oldValue, newValue);
    }
  }

  const discountValues = {
    productCoupon,
    discountValue
  };

  return discountValues;
};

export const extractDataCheckout = (context) => {
  if (context.checkout.data && context.checkout.data.products?.length >= 1) {
    return {
      ...context.checkout.data.products[0],
      creditCardPaymentMethodList:
        context.checkout.data.creditCardPaymentMethodList
    };
  } else {
    return {};
  }
};
