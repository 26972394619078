import React from 'react';
import Head from 'next/head';

type HtmlHeadProps = {
  title: string;
  url: string;
  description: string;
  keywords: string;
  product: ProductInfo;
  locale: Language;
};

function HtmlHead(props: HtmlHeadProps) {
  const titleTuned = props?.title ? `${props.title} | Hotmart` : 'Hotmart';
  const imageTuned =
    props?.product?.avatar ||
    'https://hotmart.com/product/static/images/hotmart-og-image.jpg';
  const videoTuned = props?.product?.videoLink || '';
  const formatTuned = `${props?.product?.format || ''}`.replace(
    /^category.(.*).name$/g,
    '$1'
  );

  function limitTitle(title: string): string {
    const LIMIT_TITLE = 70;

    if (title.length <= LIMIT_TITLE) {
      return title;
    }

    return title.substring(0, LIMIT_TITLE - 3) + '...';
  }

  return (
    <Head>
      <title>{limitTitle(titleTuned)}</title>
      <meta httpEquiv="x-ua-compatible" content="IE=edge" />
      <meta name="description" content={props?.description} />
      <meta name="keywords" content={props?.keywords} />
      <meta name="abstract" content={props?.description} />
      <meta name="title" property="og:title" content={titleTuned} />
      <meta name="image" property="og:image" content={imageTuned} />
      <meta name="url" property="og:url" content={props?.url} />
      <meta
        name="description"
        property="og:description"
        content={props?.description}
      />
      <meta name="video" property="og:video" content={videoTuned} />
      <meta name="site_name" property="og:site_name" content="Hotmart" />
      <meta name="type" property="og:type" content="Product" />
      <meta name="locale" property="og:locale" content={props?.locale} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@hotmart" />
      <meta name="twitter:url" content={props?.url} />
      <meta name="twitter:title" content={titleTuned} />
      <meta name="twitter:description" content={props?.description} />
      <meta name="twitter:image" content={imageTuned} />

      <meta name="theme-color" content="#F04E23" />
      <meta name="msapplication-TileColor" content="#F04E23" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta name="apple-mobile-web-app-title" content={props?.title} />
      <meta name="application-name" content={props?.title} />
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0"
      />

      <meta name="product-category" content={props?.product?.category} />
      <meta name="product-format" content={formatTuned} />
      <meta name="product-has-video" content={videoTuned ? 'true' : 'false'} />

      <link rel="canonical" href={props?.url} />
    </Head>
  );
}

export default React.memo(HtmlHead);
