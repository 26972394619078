import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback
} from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'next-i18next';
import { useRouter, withRouter } from 'next/router';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';
import { isCourseOnlineFormat } from 'utils/product/format';
import Utils from '../../global/scripts/utils';

import '@cosmos/button/variations/success.css';
import '@cosmos/button/variations/primary.css';
import '@hotmart/cosmos/dist/button/variations/large.css';

import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';

const ShoppingCart = loaderStaticImage({
  src: 'static/images/icons/shopping-cart.svg'
});
const CalendarDay = loaderStaticImage({
  src: 'static/images/icons/calendar-day.svg'
});
const Ticket = loaderStaticImage({ src: 'static/images/icons/ticket.svg' });

const LinkButton = (props) => {
  const context = useContext(ProfileContext);
  const { profile, checkout } = context;
  const { product } = profile;
  const {
    className = '',
    theme = 'default',
    text = '',
    icon = '',
    ctaIcon = '',
    prefix = '',
    functionCallback = null,
    href = '',
    onClick = null,
    style = {},
    ariaLabel,
    t,
    modal,
    size,
  } = props;
  const buttonClassName = classnames(
    'link-button',
    className,
    `link-button-${theme}`,
    {
      'checkout-closed': checkout.data.checkoutClosed,
      season: ctaIcon,
      'buy-cart__orange': isCourseOnlineFormat(profile.product?.format)
    }
  );
  const buttonClassNameTransparent = classnames(
    'btn-buy-transparent link-button',
    className,
    `link-button-${theme}`,
    { 'checkout-closed': checkout.data.checkoutClosed }
  );
  const [component, setComponent] = useState('');
  const router = useRouter();

  if (product && product.productId === 4) {
    profile.togglz = true;
  }

  const trackClickCheckout = () => {
    let offDiscount;
    if (
      profile &&
      profile.product &&
      profile.product.coupon &&
      profile.product.coupon.code
    ) {
      offDiscount = context?.query?.coupon || profile.product.coupon.code;
    }

    const { sck, src, off, xcod } = checkout;
    const url = `/product/${profile.product.slug}/${profile.product.producerReferenceCode}/checkout`;
    const linkUrl = Utils.mountLink(url, sck, src, off, offDiscount, xcod);

    router.push(linkUrl);

    if (props.functionCallback) {
      props.functionCallback();
    }
  };

  const mountTestIdButton = (theme, modal) => {
    if (theme === 'service' && modal) return 'btn-buy-service-modal';
    if (theme === 'service' && !modal) return 'btn-buy-service';
    if (theme === 'service-checkout' && modal)
      return 'btn-buy-service-checkout-modal';
    return 'btn-buy-service-checkout';
  };

  const memoizedTestIdButton = useCallback(mountTestIdButton, [
    theme,
    modal,
    text
  ]);

  const { product: { coupon } = {} } = context?.profile;

  const renderIcons = (icon) => {
    switch (icon) {
      case 'shopping-cart':
        return (
          <IconsImage
            src={ShoppingCart}
            width={20}
            height={20}
            className="link-button__icon"
          />
        );
      case 'calendar-day':
        return (
          <IconsImage
            src={CalendarDay}
            width={15}
            height={18}
            className="link-button__icon link-button__icon-calendar"
          />
        );
      case 'ticket':
        return (
          <IconsImage
            src={Ticket}
            width={20}
            height={20}
            className="link-button__icon link-button__icon-ticket"
          />
        );
      default:
        return null;
    }
  };

  const constructClassNames = (variation, size, className = '') => {
    const variationClass = ` hot-button--${variation}`;
    const sizeClass = size ? ` hot-button--${size}` : '';

    return `hot-button${variationClass}${sizeClass} ${className}`.trim();
  };

  const ButtonLink = ({ theme, classes, id, text, ariaLabel, action }) => {
    const buttonClasses = theme
      ? constructClassNames(theme, size, classes)
      : classes;

    const memoizedRenderLink = useCallback(renderIcons);

    return (
      <a
        href={href || null}
        onClick={action}
        className={buttonClasses}
        data-testid={id}
        aria-label={ariaLabel || text}
        role="button"
      >
        <div>
          <span className="button-text">{text}</span>
          <span className="button-icon">
            {icon && !ctaIcon && memoizedRenderLink(icon)}
          </span>
          {ctaIcon && <img className="campaign-tag" src={ctaIcon} alt="" />}
        </div>
      </a>
    );
  };

  const mountLinkButton = (theme, modal) => {
    switch (theme) {
      case 'success':
        if (profile.belongsToMarketplace && profile.togglz) {
          return (
            <ButtonLink
              theme={theme}
              classes={buttonClassNameTransparent}
              id="btn-buy"
              text={text}
              ariaLabel={ariaLabel}
              action={trackClickCheckout}
            />
          );
        }
        return (
          <ButtonLink
            theme={theme}
            classes={buttonClassName}
            id="btn-buy"
            text={text}
            ariaLabel={ariaLabel}
            action={onClick}
          />
        );
      case 'service':
      case 'service-checkout':
        return (
          <ButtonLink
            theme="success"
            classes={buttonClassName}
            id={memoizedTestIdButton(theme, modal)}
            text={text}
            ariaLabel={ariaLabel}
            action={onClick}
          />
        );
      default:
        return (
          <ButtonLink
            classes={buttonClassNameTransparent}
            id="btn-buy"
            text={text}
            ariaLabel={ariaLabel}
            action={trackClickCheckout}
          />
        );
    }
  };

  const memoizedLinkButton = useCallback(mountLinkButton, [
    theme,
    modal,
    href,
    text,
    ariaLabel
  ]);

  useEffect(() => {
    setComponent(memoizedLinkButton(theme, modal));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [icon, ctaIcon, text, prefix, modal, href, context, theme]);

  return <>{component}</>;
};

LinkButton.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf([
    'default',
    'success',
    'red',
    'blue',
    'service',
    'service-checkout'
  ]),
  size: PropTypes.oneOf(['lg', 'md', null]),
  text: PropTypes.string,
  icon: PropTypes.string,
  ctaIcon: PropTypes.string,
  modal: PropTypes.any,
  prefix: PropTypes.string,
  functionCallback: PropTypes.func,
  href: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  classes: PropTypes.string,
  id: PropTypes.number,
  action: PropTypes.func,
  t: PropTypes.func
};

export default withTranslation()(withRouter(React.memo(LinkButton)));
